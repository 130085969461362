import Styled from 'vue3-styled-components';
const SalesTargetWrap = Styled.div`
		height:100%;
		padding-bottom:25px;
    .ant-card{
        min-height: 435px;
				height:100%;
    }

		.s-target-list{
			margin-top:20px;
			&~h2{
				font-size:25px;
			}
		}
    .s-target-list__item{
			margin-top:30px;
			text-align:center;
				h2{
					line-height:1.4;
					font-size: 22px;
					font-weight: 600;
        }
				.btn-icon{
					margin:10px 0 21px;

				}
				.target-topline{
					border-top:1.5px solid rgba(0,0,0,.9);
				}
				.target-reward{
					padding:16px 0;
				}
        .target-revinue{
					color: ${({ theme }) => theme['success-color']};
        }
        p{
            margin-bottom: 0;
            color: ${({ theme }) => theme['gray-color']};
        }
    }
`;

const CardWrapper = Styled.figure`
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
  img {
    width: 100%;
		object-fit:cover;
		max-height:300px;
		height: 259px;
  }

  figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px;
    background: ${({ theme }) => theme['dark-color']}50;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      color: #fff;
      margin: 0px 0 10px 0;
      font-size: 30px;
      font-weight: 600;
      line-height: 35px;
    }
    p {
      line-height: 25px;
      font-size: 16px;
    }
    .feedbackSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      .author {
        color: #fff;
        .start__copy {
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')} : 10px;
        }
        span {
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }
`;

const SalesGrowthWrap = Styled.div`
    @media only screen and (max-width: 1599px){
        min-height: 435px;
        background-color: #fff;
        border-radius: 10px;
    }
    .growth-chart-wrap{
        margin-top: 58px;
    }
    .growth-list__item{
        h2{
            font-size: 22px;
            font-weight: 600;
            line-height: 1.4;
            margin-bottom: 2px;
        }
        p{
            margin-bottom: 0;
            color: ${({ theme }) => theme['gray-color']};
        }
    }
    canvas{
        margin-bottom: 0 !important;
    }
`;

const OverviewSalesCard = Styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0 2px 0;
    .icon-box{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        border-radius: 10px;
        margin-right: 10px;
				margin-bottom: 40px;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
        @media only screen and (max-width: 1399px){
            height: 40px;
            width: 40px;
        }
        @media only screen and (max-width: 991px){
            height: 60px;
            width: 60px;
        }
        img{
            max-width: 35px;
        }
        &.box-primary{
            background-color: ${({ theme }) => theme['primary-color']}10;
            path,
            circle{
                fill: ${({ theme }) => theme['primary-color']};
            }
        }
        &.box-success{
            background-color: ${({ theme }) => theme['success-color']}10;
            path,
            circle{
                fill: ${({ theme }) => theme['success-color']};
            }
        }
        &.box-secondary{
            background-color: ${({ theme }) => theme['secondary-color']}10;
            path,
            circle{
                fill: ${({ theme }) => theme['secondary-color']};
            }
        }
    }
    .card-chunk{
        h2{
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 2px;
            color: ${({ theme }) => theme['dark-color']};
        }
        span{
            color: ${({ theme }) => theme['light-gray-color']};
        }
        p{
            margin: 10px 0;
        }
    }
`;

export { SalesTargetWrap, SalesGrowthWrap, OverviewSalesCard, CardWrapper };
