<template>
  <Main>
    <div class="start">
      <sdPageHeader :title="' '" />
      <a-row :gutter="15">
        <!--    User Accounts Start    -->
        <!--<a-col :lg="24">
          <sdPageHeader :title="i18n.t('start.myAccounts')" />
        </a-col> -->
        <a-col :xl="24" :lg="24" :xs="24" v-if="accounts?.length">
          <a-row :gutter="15">
            <a-col :xl="8" :lg="12" :xs="24" v-for="index of 3" :key="index">
              <StartBills
                :BillName="accounts[index - 1]?.attributes.kind"
                @openModal="openModal"
                :AccountId="+accounts[index - 1]?.id"
                :BillCount="+accounts[index - 1]?.attributes.amount"
                :BillIcon="icons[index]"
              />
            </a-col>
          </a-row>
        </a-col>
        <a-col :xl="8" :lg="24" :xs="24" v-if="!accounts?.length">
          <sdCards headless>
            <a-skeleton active :avatar="{ shape: 'square' }" :paragraph="{ rows: 2 }" />
          </sdCards>
        </a-col>
        <a-col :xl="8" :lg="24" :xs="24" v-if="!accounts?.length">
          <sdCards headless>
            <a-skeleton active :avatar="{ shape: 'square' }" :paragraph="{ rows: 2 }" />
          </sdCards>
        </a-col>
        <a-col :xl="8" :lg="24" :xs="24" v-if="!accounts?.length">
          <sdCards headless>
            <a-skeleton active :avatar="{ shape: 'square' }" :paragraph="{ rows: 2 }" />
          </sdCards>
        </a-col>
        <!--    User Accounts End    -->
        <!--    User Active Deposits Start    -->
        <a-col :lg="24" v-if="activeDeposits?.length">
          <sdPageHeader :title="i18n.t('start.activeDeposits')"> </sdPageHeader>
        </a-col>
        <a-col class="w-100" :md="24" v-if="activeDeposits?.length">
          <sdCards headless>
            <TableWrapper class="table-data-view table-responsive">
              <a-table
                class="ant-table-colored"
                :pagination="false"
                :rowClassName="(record, index) => (index % 2 !== 0 ? 'table-row__colored' : null)"
                :dataSource="activeDeposits"
                :columns="columnsActiveDeposits"
                rowKey="id"
              >
                <template #operation_id="record">
                  <p @click="openModal(record.record.id)" style="color: #1890ff; cursor: pointer">
                    {{ record.record.attributes.operation_id ?? record.record.attributes.exchange_info.txn_id ?? '' }}
                  </p>
                </template>
                <template #exchange_amount="record">
                  {{
                    record.record.attributes.exchange_amount ??
                    record.record.attributes.exchange_info.exchange_amount ??
                    ''
                  }}
                  {{
                    record.record.attributes.exchange_currency ??
                    record.record.attributes.exchange_info.exchange_currency ??
                    ''
                  }}
                </template>
                <template #exchange_rate="record">
                  {{
                    record.record.attributes.exchange_rate ??
                    i18n.n(
                      record.record.attributes.exchange_info.exchange_amount / record.record.attributes.amount,
                      'decimal',
                    ) ??
                    ''
                  }}
                </template>
                <template #request_amount="record">
                  <div v-if="record.record.attributes.request">
                    {{ i18n.n(+record.record.attributes.request.amount, 'decimal') }}
                    {{ 'USDT' }}
                  </div>
                  <div v-else-if="record.record.attributes.request_amount">
                    {{ record.record.attributes.request_amount }}
                  </div>
                  <div v-else></div>
                </template>
                <template #created_at="record">
                  {{ i18n.d(record.record.attributes.created_at, 'long') ?? '' }}
                </template>
              </a-table>
            </TableWrapper>
          </sdCards>
        </a-col>
        <!--    User Active Deposits End    -->

        <a-col :lg="24">
          <sdPageHeader :title="i18n.t('start.infoBlockTitle')" />
        </a-col>
        <a-col :lg="8" :xs="24" v-if="+percentage.price">
          <sdCards headless class="start__header" v-if="percentage.price">
            <OverviewSalesCard>
              <div class="icon-box box-success">
                <SvgConverter :src="require('@/static/img/icon/Profit.svg')" />
              </div>
              <div class="card-chunk">
                <h2>${{ percentage.price }}</h2>
                <span>{{ i18n.t('start.tokenPrice') }}</span>
                <p>
                  <span class="growth-upward"> <sdFeatherIcons type="arrow-up" /> 16% </span>
                  <span>{{ i18n.t('start.growth', { round: 'Round №3' }) }}</span>
                </p>
              </div>
            </OverviewSalesCard>
          </sdCards>
          <a-skeleton active :paragraph="{ rows: 2 }" v-else />
        </a-col>
        <a-col :lg="8" :xs="24" v-if="percentage.price">
          <TokensInfo :percentage="percentage" />
        </a-col>
        <a-col :xl="8" :lg="24" :xs="24" v-else>
          <sdCards headless>
            <a-skeleton active :paragraph="{ rows: 2 }" />
          </sdCards>
        </a-col>
        <a-col :lg="8" :xs="24">
          <StartHeader />
        </a-col>

        <!--Options start-->
        <!--<a-col :lg="24" :xs="24">
          <a-page-header :title="i18n.t('option.title')">
            <template #extra>
              <sdButton size="small" type="primary" @click="toggleBuyOptionModal" v-if="optionPurchases.length > 0">
                {{ i18n.t('option.buy') }}
              </sdButton>
            </template>
          </a-page-header>
        </a-col>
        <a-col :xl="8" :lg="12" :xs="24" v-for="(item, index) of optionPurchases" :key="index">
          <CardOption :data="item.attributes" />
        </a-col>

        <a-col :xs="24" v-if="optionPurchases.length == 0">
          <EmptyOptions :isLoading="isLoadingInvest" v-on:openModal="toggleBuyOptionModal" />
        </a-col>-->
        <!--Options end-->

        <a-col :lg="24" :xs="24">
          <StartLootBox
            :closed="closedLootBoxes"
            :opened="openedLootBoxes"
            :usersOpen="usersOpen"
            v-model:openLootBoxVisible="lootBoxModalVisible"
            v-model:buyTraitVisible="buyTraitModalVisible"
          />
        </a-col>

        <a-col :lg="24" :xs="24">
          <a-page-header :title="i18n.t('start.tradingAccounts')">
            <template #extra>
              <sdButton size="large" type="primary" class="download__button" @click="changeDownloadModal">
                <img :src="require('@/static/img/icon/mt5_logo.png')" />
                {{ i18n.t('start.downloadMTT') }}
              </sdButton>
              <sdButton size="large" type="success" raised @click="changeCreateTradingAccount">
                <sdFeatherIcons type="plus" size="18" style="margin-right: 5px" />
                {{ i18n.t('start.createTradingAccount') }}
              </sdButton>
            </template>
            <template #footer>
              <a-tabs v-model:activeKey="tradingAccountsPage" @change="changeTab">
                <a-tab-pane key="real" :tab="i18n.t('accounts.trading.real', 0)" />
                <a-tab-pane key="demo" :tab="i18n.t('accounts.trading.demo', 0)" />
              </a-tabs>
            </template>
          </a-page-header>
        </a-col>
        <a-col :xl="7" :lg="11" :xs="24" v-for="(account, index) of tradingAccounts" :key="index">
          <TradingAccountBills
            :BillAccount="account.attributes"
            :AccountId="+account.id"
            :BillIcon="tradingAccountsPage === 'real' ? 'activity' : 'tool'"
            v-model:closingModal="closeTradingModal"
            @openModal="openModal"
            @openPublishModal="openPublishModal"
          />
        </a-col>

        <a-col :xl="3" :lg="3" :xs="24" v-if="tradingAccounts.length > 2">
          <div class="all-accounts">
            <router-link :to="`/trading-accounts/${tradingAccountsPage}`">
              <sdFeatherIcons type="arrow-right" />
              <p>{{ i18n.t('start.allAccounts') }}</p>
            </router-link>
          </div>
        </a-col>

        <a-col :xs="24" v-else-if="tradingAccounts.length == 0">
          <sdCards headless>
            <p
              class="trading__nothing"
              v-html="
                i18n.t('accounts.trading.haventAccs', { account: i18n.t(`accounts.trading.${tradingAccountsPage}`, 1) })
              "
              v-if="!isLoadingTrading"
            />
            <div class="loading-data-trading" v-else>
              <a-spin size="large"></a-spin>
            </div>
          </sdCards>
        </a-col>

        <a-col :lg="24" :xs="24">
          <a-page-header :title="i18n.t('accounts.invest.title')" />
        </a-col>
        <a-col :xl="7" :lg="11" :xs="24" v-for="(account, index) of investorAccounts" :key="index">
          <PublicAccountBills
            :BillAccount="account.attributes"
            :AccountId="+account.id"
            accountType="investor_accounts"
            v-model:publishModal="publishModal"
            v-model:investModal="investModal"
            v-model:closeModal="closeInvestorModal"
          />
        </a-col>

        <a-col :xl="3" :lg="3" :xs="24" v-if="investorAccounts.length > 2">
          <div class="all-accounts">
            <router-link :to="`/trading/invest/`">
              <sdFeatherIcons type="arrow-right" />
              <p>{{ i18n.t('start.allAccounts') }}</p>
            </router-link>
          </div>
        </a-col>

        <a-col :xs="24" v-else-if="investorAccounts.length == 0">
          <EmptyInvests :isLoading="isLoadingInvest" />
        </a-col>

        <a-col class="w-100" :md="24">
          <StartInvite />
        </a-col>
      </a-row>
      <ModalCash :visibleModal="visibleModalAction" @closeModal="closeModal" />
      <sdModal
        centered
        type="primary"
        :title="i18n.t('start.coinPaymentsDepositCheck')"
        :visible="visibleModalDeposit"
        :onCancel="closeModal"
        :width="windowSize > 700 ? 700 : ''"
      >
        <DepositCoinpayments :depositId="depositId" />
      </sdModal>
    </div>
    <!--<div class="loading-data" v-else>
      <a-spin size="large"></a-spin>
    </div>-->
    <sdModal
      centered
      type="primary"
      :title="i18n.t('authPage.emailAuth', 2)"
      :visible="mailAccModal"
      :onCancel="closeModalMail"
      v-if="mailAcc"
    >
      <div class="mail__modal" v-if="!mailAcc.errors">
        <p class="forgot-text">
          {{ i18n.t('authPage.emailAuthInfo', 2) }}
        </p>
      </div>
      <p v-else>
        {{ i18n.t('authPage.token', mailAcc.errors.confirmation_token ? 2 : 1) }}
        {{
          mailAcc.errors.email
            ? mailAcc.errors.email[0]
            : mailAcc.errors.confirmation_token
            ? mailAcc.errors.confirmation_token[0]
            : mailAcc.errors.email ?? mailAcc.errors.confirmation_token ?? 'Ошибка'
        }}
      </p>
    </sdModal>
    <sdModal
      centered
      :title="i18n.t(`start.downloadMTT`)"
      :visible="visibleDownloadModal"
      :onCancel="changeDownloadModal"
    >
      <img class="metatrade-full" :src="require('@/static/img/icon/logo-meta5-full.png')" />
      <div class="df-jcsb-aic">
        <a :href="links.windows" target="_blank">
          <sdButton class="metatrade-download" type="primary" size="large">
            <img :src="require('@/static/img/icon/windows.svg')" />
            <p>windows</p>
          </sdButton>
        </a>
        <a :href="links.mac" target="_blank">
          <sdButton class="metatrade-download" type="primary" size="large">
            <img :src="require('@/static/img/icon/mac.svg')" />
            <p>mac</p>
          </sdButton>
        </a>
        <a :href="links.linux" target="_blank">
          <sdButton class="metatrade-download" type="primary" size="large">
            <img :src="require('@/static/img/icon/linux.svg')" />
            <p>linux</p>
          </sdButton>
        </a>
        <a :href="links.appstore" target="_blank">
          <sdButton class="metatrade-download" type="primary" size="large">
            <img :src="require('@/static/img/icon/appstore.svg')" />
            <p>appstore</p>
          </sdButton>
        </a>
        <a :href="links.android" target="_blank">
          <sdButton class="metatrade-download" type="primary" size="large">
            <img :src="require('@/static/img/icon/android.svg')" />
            <p>android</p>
          </sdButton>
        </a>
      </div>
    </sdModal>

    <OpenLootBox
      :closed="closedLootBoxes"
      v-model:openLootBoxVisible="lootBoxModalVisible"
      v-model:buyTraitVisible="buyTraitModalVisible"
    />

    <BuyTrait :closed="closedLootBoxes" v-model:buyTraitVisible="buyTraitModalVisible" />
    <sdModal
      centered
      :title="i18n.t(`start.createTokenAccount`)"
      :visible="visibleCreateTradingAccount"
      :onCancel="changeCreateTradingAccount"
      class="create-account"
      :width="windowSize > 850 ? 350 : 320"
    >
      <CreateTradingModal :currentTradingAcc="tradingAccountsPage" @closeModal="changeCreateTradingAccount" />
    </sdModal>
    <PublishModal v-model:data="publishModal" />
    <InvestPublicModal v-model:data="investModal" />
    <CloseInvestModal v-model:data="closeInvestorModal" />
    <CloseTradingModal v-model:data="closeTradingModal" />
    <BuyOptionModal v-model:data="optionData" @dataSended="reloadPurchasesOptionData" />
  </Main>
</template>
<script>
import { Main, TableWrapper } from '../styled';
import { OverviewSalesCard } from './style';
import { defineAsyncComponent, ref, computed, onMounted } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import SvgConverter from '@/components/utilities/SvgConverter';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import { Notification } from 'ant-design-vue';
// import shop from "@/vuex/modules/shop/axios/actionCreator";
const StartInvite = defineAsyncComponent(() => import('./overview/StartInvite'));
const StartBills = defineAsyncComponent(() => import('./overview/StartBills'));
const TradingAccountBills = defineAsyncComponent(() => import('./overview/TradingAccountBills'));
const ModalCash = defineAsyncComponent(() => import('@/components/modals/ModalCash'));
const DepositCoinpayments = defineAsyncComponent(() => import('@/components/modals/overview/DepositCoinpayments'));
const CreateTradingModal = defineAsyncComponent(() => import('@/components/modals/CreateTradingModal'));
const StartHeader = defineAsyncComponent(() => import('./overview/StartHeader'));
const TokensInfo = defineAsyncComponent(() => import('./overview/TokensInfo'));
const OpenLootBox = defineAsyncComponent(() => import('@/components/modals/OpenLootBox.vue'));
const BuyTrait = defineAsyncComponent(() => import('@/components/modals/BuyTrait.vue'));
const StartLootBox = defineAsyncComponent(() => import('./overview/StartLootBox.vue'));
const PublishModal = defineAsyncComponent(() => import('@/components/modals/PublishModal'));
const PublicAccountBills = defineAsyncComponent(() => import('@/components/elements/PublicAccountBills'));
const InvestPublicModal = defineAsyncComponent(() => import('@/components/modals/InvestPublicModal.vue'));
const CloseInvestModal = defineAsyncComponent(() => import('@/components/modals/CloseInvestModal.vue'));
const EmptyInvests = defineAsyncComponent(() => import('@/components/elements/EmptyInvests'));
const CloseTradingModal = defineAsyncComponent(() => import('@/components/modals/CloseTradingModal'));

const EmptyOptions = defineAsyncComponent(() => import('@/view/options/EmptyOptions'));
const BuyOptionModal = defineAsyncComponent(() => import('@/view/options/BuyOptionModal'));
const CardOption = defineAsyncComponent(() => import('@/view/options/CardOption'));

const Start = {
  name: 'Start',
  components: {
    Main,
    TableWrapper,
    OverviewSalesCard,
    StartLootBox,
    StartBills,
    StartInvite,
    TradingAccountBills,
    ModalCash,
    DepositCoinpayments,
    CreateTradingModal,
    StartHeader,
    SvgConverter,
    TokensInfo,
    OpenLootBox,
    BuyTrait,
    PublishModal,
    PublicAccountBills,
    InvestPublicModal,
    CloseInvestModal,
    EmptyInvests,
    CloseTradingModal,
    EmptyOptions,
    BuyOptionModal,
    CardOption,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { state, dispatch } = useStore();
    const icons = ['dollar-sign', 'trending-up', 'percent'];
    const i18n = useI18n();
    const visibleModalAction = ref(false);
    const visibleModalDeposit = ref(false);
    const isDepositsView = ref(true);
    const depositId = ref(null);
    const activeKey = ref('1');
    const mailAccModal = ref(false);
    const tradingAccountsPage = ref('real');
    const visibleCreateTradingAccount = ref(false);
    const visibleDownloadModal = ref(false);
    const lootBoxModalVisible = ref(false);
    const buyTraitModalVisible = ref(false);
    const publishModal = ref({ data: null, visible: false });
    const investModal = ref({ data: null, visible: false });
    const closeInvestorModal = ref({ data: null, visible: false });
    const closeTradingModal = ref({ data: null, visible: false });
    const optionData = ref({ data: null, visible: false });

    const links = {
      windows: process.env.VUE_APP_WINDOWS_LINK,
      mac: process.env.VUE_APP_MAC_LINK,
      linux: process.env.VUE_APP_LINUX_LINK,
      appstore: process.env.VUE_APP_APPSTORE_LINK,
      android: process.env.VUE_APP_ANDROID_LINK,
    };

    const columnsActiveDeposits = computed(() => [
      {
        title: i18n.t('start.operation_id'),
        dataIndex: 'operation_id',
        key: 'operation_id',
        slots: { customRender: 'operation_id' },
      },
      {
        title: i18n.t('start.request_amount'),
        dataIndex: 'request_amount',
        key: 'request_amount',
        slots: { customRender: 'request_amount' },
      },
      {
        title: i18n.t('start.exchange_amount'),
        dataIndex: 'exchange_amount',
        key: 'exchange_amount',
        slots: { customRender: 'exchange_amount' },
      },
      {
        title: i18n.t('start.exchange_rate'),
        dataIndex: 'exchange_rate',
        key: 'exchange_rate',
        slots: { customRender: 'exchange_rate' },
      },
      {
        title: i18n.t('start.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
        slots: { customRender: 'created_at' },
      },
    ]);

    const mailAcc = computed(() => state.profile.mailAcc);
    const confirmCode = computed(() => route.redirectedFrom?.params.confirmationCode);
    const accounts = computed(() => state.accounts.data);
    const stats = computed(() => state.accounts.stats);
    const activeDeposits = computed(() => state.accounts.activeDeposits);
    const graphStats = computed(() => state.accounts.graphStats);
    const windowSize = computed(() => window.innerWidth);
    const tradingAccounts = computed(() => state.tradingAccounts.data);
    const isLoadingTrading = computed(() => state.tradingAccounts.loading);
    const closedLootBoxes = computed(() => state.lootBoxes.closedData);
    const openedLootBoxes = computed(() => state.lootBoxes.openedData);
    const usersOpen = computed(() => state.lootBoxes.usersOpen);
    const investorAccounts = computed(() => state.publicAccounts.invest.data);
    const isLoadingInvest = computed(() => state.publicAccounts.loading);
    const optionPurchases = computed(() => state.shop.option.data);

    const percentage = computed(() => {
      let tokenInfo = state.accounts.tokenSale;
      tokenInfo.percent = (+tokenInfo.sale ? (tokenInfo.sale / tokenInfo.total) * 100 : 100).toFixed(2);
      return tokenInfo;
    });
    const changeView = () => {
      isDepositsView.value = !isDepositsView.value;
    };

    const closeModal = (closeWithoutPush) => {
      visibleModalAction.value = false;
      visibleModalDeposit.value = false;
      depositId.value = null;
      if (!closeWithoutPush) {
        router.push('/');
      }
    };

    const openModal = (data) => {
      if (data) {
        depositId.value = data ? data : null;
        visibleModalDeposit.value = true;
        dispatch('getCoinpaymentsDepositInfo', data);
      } else {
        visibleModalAction.value = true;
      }
    };
    const openPublishModal = (data) => {
      if (!publishModal.value.visible) {
        publishModal.value = { data, visible: true };
      } else {
        publishModal.value = { data: null, visible: false };
      }
    };

    const closeModalMail = () => {
      mailAccModal.value = !mailAccModal.value;
    };

    const changeCreateTradingAccount = (type) => {
      visibleCreateTradingAccount.value = !visibleCreateTradingAccount.value;

      if (typeof type == 'string') {
        dispatch('getTradingAccounts', { page: 1, per_page: 3, type: tradingAccountsPage.value });

        setTimeout(() => {
          dispatch('getTradingAccounts', { page: 1, per_page: 3, type: tradingAccountsPage.value });
        }, 5000);
      } else {
        dispatch('clearTradingErrors');
      }
    };

    const changeDownloadModal = () => {
      visibleDownloadModal.value = !visibleDownloadModal.value;
    };

    const changeTab = () => {
      dispatch('getTradingAccounts', { page: 1, per_page: 3, type: tradingAccountsPage.value });
    };

    const changeLootBoxModal = () => {
      lootBoxModalVisible.value = !lootBoxModalVisible.value;
    };

    const changeBuyTraitModal = () => {
      buyTraitModalVisible.value = !buyTraitModalVisible.value;
    };

    onMounted(() => {
      route.params.accId && !visibleModalAction.value ? router.push('/') : '';
      const redirectFrom = route.redirectedFrom;

      if (!closedLootBoxes.value.length || !openedLootBoxes.value.length) {
        dispatch('axiosLootBoxesGetData');
      }

      dispatch('getDashboard');

      dispatch('axiosAccountsGetData');

      if (!state.shop.offersData.length) {
        dispatch('axiosOffersGetData');
      }

      dispatch('getTradingAccounts', { page: 1, per_page: 3, type: 'real' });

      dispatch('getPublicAccounts', { page: 1, per_page: 3, restart: true, type: 'investor_accounts' });

      if (redirectFrom?.query.lang) {
        Notification.open({
          message: i18n.t('messages.youAreAuthorized'),
          description: '',
          duration: 6,
        });
      }
      if (confirmCode.value && redirectFrom.name == 'mailAccept') {
        dispatch('mailAccept', confirmCode.value).then(() => {
          mailAccModal.value = true;
        });
      }

      dispatch('getPurchases', { page: 1, per_page: 3, kind: 'option' });
    });

    const toggleBuyOptionModal = () => {
      optionData.value.visible = !optionData.value.visible;
    };
    const reloadPurchasesOptionData = () => {
      dispatch('getPurchases', { page: 1, per_page: 3, kind: 'option' });
    };

    return {
      i18n,
      links,
      visibleModalAction,
      accounts,
      icons,
      stats,
      activeDeposits,
      columnsActiveDeposits,
      visibleModalDeposit,
      depositId,
      isDepositsView,
      graphStats,
      activeKey,
      windowSize,
      mailAcc,
      mailAccModal,
      tradingAccountsPage,
      tradingAccounts,
      investorAccounts,
      isLoadingInvest,
      visibleCreateTradingAccount,
      visibleDownloadModal,
      percentage,
      isLoadingTrading,
      lootBoxModalVisible,
      closedLootBoxes,
      openedLootBoxes,
      usersOpen,
      buyTraitModalVisible,
      publishModal,
      investModal,
      closeInvestorModal,
      closeTradingModal,
      openPublishModal,
      changeBuyTraitModal,
      changeTab,
      changeDownloadModal,
      changeCreateTradingAccount,
      closeModal,
      openModal,
      changeView,
      closeModalMail,
      changeLootBoxModal,
      optionPurchases,
      optionData,
      toggleBuyOptionModal,
      reloadPurchasesOptionData,
    };
  },
};
export default Start;
</script>

<style scoped lang="scss">
.ant-table-colored :deep(.table-row__colored) td {
  background-color: #f7f7ff;
}
.status-header {
  display: flex;
  align-items: center;
  align-items: center;
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #908f9e;
  }
  &__text {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    padding: 5px;
    border-radius: 5px;
    background: rgba(122, 118, 255, 0.2);
    color: #2c9aea;
  }
}
.mail__modal {
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
  }
}
</style>
